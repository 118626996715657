import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { getCluster } from './getCluster'
import { api, tenantApi } from '../endpoint'

const createPostHeaders = (payload: Record<string, any>) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(payload),
  method: 'POST',
})

const defaultApiHandler = (response: Response) => {
  if (response.status >= 200 && response.status < 400) {
    return response.json()
  }

  return response
    .json()
    .catch(() => {
      // Couldn't parse the JSON
      throw new Error(response.status.toString())
    })
    .then(({ message }) => {
      // Got valid JSON with error response, use it
      throw new Error(message || response.status)
    })
}

export const apiService = async (
  tenantDomain: string,
  url: string,
  payload?: Record<string, any>
) => {
  const cluster = await getCluster(tenantDomain)
  return fetchPolyfill(api(url, cluster), {
    credentials: 'include',
    ...(payload && createPostHeaders(payload)),
  }).then(defaultApiHandler)
}

export const tenantService = async (
  tenantDomain: string,
  url: string,
  payload?: Record<string, any>
) => {
  return fetchPolyfill(tenantApi(tenantDomain, url), {
    credentials: 'include',
    ...(payload && createPostHeaders(payload)),
  }).then(defaultApiHandler)
}
